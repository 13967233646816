const env = process.env.NODE_ENV;
const configs = {
  development: {
    baseUrl:  '', // 'http://47.113.200.218:3030', //
  },
  qa: {
    baseUrl: 'https://test.yatu.di6gan.cn',
  },
  production: {
    baseUrl: 'https://yatu.di6gan.cn',
  },
};

export const config = configs[env];

export const acceptImageFormats = '.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.webp,.WEBP,.awebp,.AWEBP,.heic,.HEIC,.pic_hd';
