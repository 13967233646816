import { showErrorMessage } from '@/utils/index';
export default {
  name: 'PageHeader',
  footer: 'footer',
  computed: {
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      dialogFormVisible: true,
      list: [{
        to: '/index',
        name: '批量处理',
        key: 0
      }, {
        to: '/single',
        name: '长图处理',
        key: 1
      }, {
        to: '/url',
        name: '图片链接处理',
        key: 2
      }, {
        to: '/weburl',
        name: '网页转图片',
        key: 3
      }, {
        to: '/towcode',
        name: '二维码生成',
        key: 4
      }
      // {
      //   to: '/sprite',
      //   name: '雪碧图合成',
      //   key: 2,
      // },
      ]
    };
  },
  methods: {
    addFavorite(obj, opts) {
      var _t, _u;
      if (typeof opts != 'object') {
        _t = document.title;
        _u = location.href;
      } else {
        _t = opts.title || document.title;
        _u = opts.url || location.href;
      }
      try {
        window.external.addFavorite(_u, _t);
      } catch (e) {
        if (window.sidebar) {
          obj.href = _u;
          obj.title = _t;
          obj.rel = 'sidebar';
        } else {
          showErrorMessage('抱歉，您所使用的浏览器无法完成此操作。\n\n请使用 windows电脑使用 Ctrl + D , macOS 电脑使用 Command + D，将本页加入收藏夹！');
        }
      }
    },
    shareFun() {
      const url = window.location.href;
      navigator.clipboard.writeText(url).then(() => {
        showErrorMessage("已复制到剪切板");
      }).catch(() => {
        showErrorMessage('抱歉，您所使用的浏览器不支持次操作，请手动复制');
      });
    }
  }
};