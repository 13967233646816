import axios from 'axios';
import { config } from '@/config';
// import { showErrorMessage } from '@/utils/index';

// 获取所有url img

export async function webdownloadimgallAPI(data, headers) {
  return axios({
    method: 'post',
    url: config.baseUrl + '/dcwytimgapi/process/webdownloadimgall',
    data,
    headers: headers || {},
  });
}



// 获取网络url 图片接口
export async function processURL(data, headers) {
  return axios({
    method: 'post',
    url: config.baseUrl + '/dcwytimgapi/process/url',
    data,
    headers: headers || {},
  });
}


// 获取页url 长图图片接口
export async function longWebURL(data, headers) {
  let urls = config.baseUrl + '/dcwytimgapi/process/weburl'
  if (data.type === '2') {
    urls = config.baseUrl + '/dcwytimgapi/process/getwepdf'
  }
  return axios({
    method: 'post',
    url: urls,
    data,
    headers: headers || {},
  });
}


// 获取页url 所有图片
export async function getImageAll(data, headers) {
  let urls = config.baseUrl + '/dcwytimgapi/process/webimgall'
  if (data.type === '2') {
    urls = config.baseUrl + '/dcwytimgapi/process/webimgall'
  }
  return axios({
    method: 'post',
    url: urls,
    data,
    headers: headers || {},
  });
}



// 测试限流方案
export async function getLimite(data, headers) {
  let urls = config.baseUrl + '/dcwytimgapi/process/limiter'

  return axios({
    method: 'get',
    url: urls,
    headers: headers || {},
  });
}


// 测试限流方案
export async function getLimite2(data, headers) {
  let urls = config.baseUrl + '/dcwytimgapi/process/l2limiter'

  return axios({
    method: 'get',
    url: urls,
    headers: headers || {},
  });
}



