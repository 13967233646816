import PageSection from './PageSection.vue';
export default {
  name: 'Introduction',
  components: {
    PageSection
  },
  data() {
    return {
      section1: {
        title: '为什么应该选择压图处理图片 ？',
        list: [{
          // image: require('../assets/018-like.png'),
          title: '操作简单便捷',
          desc: '可一次性上传多张需要压缩的图片文件，批量进行图片压缩处理；'
        }, {
          // image: require('../assets/024-warning.png'),
          title: '限制少',
          desc: '每次添加的图片数量、尺寸、大小不做限制，即使是相机导出的大图也可直接处理。'
        }, {
          // image: require('../assets/020-add-1.png'),
          title: '功能丰富',
          desc: '支持格式转换、压缩质量可调节、长图片分割'
        }]
      },
      section2: {
        title: '压图可以处理哪些文件 ？',
        list: [{
          image: require('../assets/037-png.png'),
          format: 'png'
        }, {
          image: require('../assets/038-jpg.png'),
          format: 'jpg'
        }, {
          image: require('../assets/webp.png'),
          format: 'webp'
        }]
      },
      section3: {
        title: '如何使用压图？',
        list: [{
          title: '1.设定好图片的处理参数',
          image: require('../assets/035-setting.png')
        }, {
          title: '2.将需要处理的多张图片添加至该页面',
          image: require('../assets/031-photo-1.png')
        }, {
          title: '3.图片压缩完毕后将其保存至本地即可。',
          image: require('../assets/027-cloud-computing.png')
        }]
      }
    };
  }
};