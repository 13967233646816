const contentDisposition = require('content-disposition');
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { config, acceptImageFormats } from '../config';

// const acceptImageFormats = '.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.webp,.WEBP,.awebp,.AWEBP,.heic,.HEIC,.pic_hd'
const imgUrlReg = new RegExp(`(.+\\/)+.+(\\.(${acceptImageFormats.replaceAll('.', '').replaceAll(',', '|')}))$`);

function isValidImgUrl(url = '') {
  return imgUrlReg.test(url);
}

function getType(contentType) {
  if (/application\/json/.test(contentType)) {
    return 'json';
  }
  if (/application\/octet-stream/.test(contentType)) {
    return 'stream';
  }
  return '';
}

export function showErrorMessage(message) {
  message &&
    ElMessage({
      type: 'error',
      offset: 90,
      message,
    });
}

/* 单图片下载 */
export function downloadSingle(file) {
  axios({
    method: 'post',
    url: config.baseUrl + '/dcwytimgapi/download/single',
    data: {
      name: file.name,
      src: file.src,
    },
    responseType: 'blob',
  })
    .then(async (res) => {
      // console.log('download single 1: ', res);
      const type = getType(res.headers['content-type']);
      const contentDispositionData = contentDisposition.parse(res.headers['content-disposition']);
      // console.warn(contentDispositionData);
      const filename = decodeURIComponent(contentDispositionData.parameters.filename);
      console.log(filename)

      let message = '';
      if (type === 'json') {
        let data = await res.data.text();
        data = JSON.parse(data);
        console.log('download single 2: ', data);
        message = data.message || '';
        message && showErrorMessage(message);
      } else if (type === 'stream') {
        const href = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.download =  file.name; // filename;
        link.href = href;
        link.click();
        window.URL.revokeObjectURL(href);
      } else {
        showErrorMessage('操作失败!');
      }
    })
    .catch((err) => {
      console.error('download error: ', err);
      showErrorMessage('操作失败!');
    });
}

/* 多图下载，服务端返回压缩包文件 */
export function downloadBatch(fileList) {
  axios({
    method: 'post',
    url: config.baseUrl + '/dcwytimgapi/download/batch',
    data: {
      list: fileList,
    },
  })
    .then((res) => {
      console.log('download batch: ', res, Date.now());
      if (res?.data?.data) {
        window.location.href = res.data.data;
      } else {
        showErrorMessage(res.data.message || '操作失败!');
      }
    })
    .catch((err) => {
      console.error('download error: ', err);
      showErrorMessage('操作失败!');
    });
}

// 检测图片url是否合法
export function checkImageUrl(url = '') {
  if (!url || url.startsWith('/')) {
    return false;
  }

  return isValidImgUrl(url);
}

export function getFileInfoByPath(src = '') {
  const list = src.split('/');
  const length = list.length;
  return {
    name: list[length - 1],
  };
}
