import PageHeader from './components/PageHeader.vue';
import PageFooter from './components/PageFooter.vue';
import Introduction from './components/Introduction.vue';
import { getLimite, getLimite2 } from './api/index';
export default {
  name: 'App',
  data() {
    return {
      isdiv: false,
      isShowQQGroup: false // 控制QQ群显隐
    };
  },
  methods: {
    async limiterFn(type) {
      if (type == 1) {
        const res = await getLimite();
        console.log('limiterFn1', res);
      } else {
        const res = await getLimite2();
        console.log('limiterFn2', res);
      }
    },
    showFn() {
      this.isShowQQGroup = !this.isShowQQGroup;
    }
  },
  components: {
    PageHeader,
    PageFooter,
    Introduction
  }
};