import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-09708944"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "introduction"
};
const _hoisted_2 = {
  class: "section-list"
};
const _hoisted_3 = {
  class: "section-item-title"
};
const _hoisted_4 = {
  class: "section-item-desc"
};
const _hoisted_5 = {
  class: "section-list"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "section-list"
};
const _hoisted_8 = {
  class: "section-item-title--normal"
};
const _hoisted_9 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_page_section = _resolveComponent("page-section");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_page_section, {
    title: $data.section1.title,
    class: "bg-gray"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.section1.list, item => {
      return _openBlock(), _createElementBlock("div", {
        key: item.title,
        class: "section-item"
      }, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.title), 1), _createElementVNode("p", _hoisted_4, _toDisplayString(item.desc), 1)]);
    }), 128))])]),
    _: 1
  }, 8, ["title"]), _createVNode(_component_page_section, {
    title: $data.section2.title
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.section2.list, item => {
      return _openBlock(), _createElementBlock("div", {
        key: item.format,
        class: "section-item"
      }, [_createElementVNode("img", {
        class: "section-item-icon",
        src: item.image
      }, null, 8, _hoisted_6)]);
    }), 128))])]),
    _: 1
  }, 8, ["title"]), _createVNode(_component_page_section, {
    title: $data.section3.title,
    class: "bg-gray"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.section3.list, item => {
      return _openBlock(), _createElementBlock("div", {
        key: item.title,
        class: "section-item"
      }, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1), _createElementVNode("img", {
        class: "section-item-icon",
        src: item.image
      }, null, 8, _hoisted_9)]);
    }), 128))])]),
    _: 1
  }, 8, ["title"])]);
}