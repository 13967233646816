// eslint-disable-next-line no-unused-vars
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../components/Batch.vue'),
  },
  {
    path: '/single',
    name: 'single',
    component: () => import('../components/Single.vue'),
  },
  {
    path: '/url',
    name: 'url',
    component: () => import('../components/Url.vue'),
  },
  {
    path: '/weburl',
    name: 'weburl',
    component: () => import('../components/WebUrl.vue'),
  },
  {
    path: '/towcode',
    name: 'towcode',
    component: () => import('../components/TowCode.vue'),
  },
  {
    path: '/sprite',
    name: 'sprite',
    component: () => import('../components/Sprite.vue'),
  },
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

export default router;
