import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0fb28e60"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "section"
};
const _hoisted_2 = {
  class: "main"
};
const _hoisted_3 = {
  key: 0,
  class: "section-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.title ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.title), 1)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)])]);
}